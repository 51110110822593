<template>
  <b-card 
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>{{ $t('Table.Instansi.Title') }}</label>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" :to="{ name: 'setups-instansi-create' }">
              <span class="text-nowrap">{{ $t('Create') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
        <b-table 
          :fields="fields" 
          :items="items"
          striped
          responsive
          class="mb-0"
        >
            <template #head()="scope">
               <div
                 class="text-nowrap">
                {{ $t(`Table.Instansi.${scope.label}`) }}
                </div>
            </template>
            

            <template #cell(Check)="row">
                <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                    class="hidden"
                >
                </b-form-checkbox>
                <feather-icon
                  class="cursor-pointer"
                  :icon="row.detailsShowing ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  @click="row.toggleDetails(); fetchDataListWarehouse(row.item.id, row.detailsShowing);"
                  size="24"
                /> 
                   <span class="menu-title"> {{ $t('Warehouse') }}</span>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
                <b-card v-if="row.item.id === id_instansi ? 'hidden' : ''">                   
                    <b-col
                      class="mb-2"
                      cols="12"
                      md="12"
                    >
                        <b-button variant="outline-secondary" @click="row.toggleDetails" class="mr-1">
                          <span class="text-nowrap">{{ $t('Hide') }}</span>
                        </b-button>
                        <b-button 
                          id="toggle-btn"
                          variant="primary" 
                          v-b-modal.modal-center 
                        >
                        <span class="text-nowrap">{{ $t('Create') }}</span>
                        </b-button>
                        <!-- Modal  -->
                          <b-modal
                            id="modal-center"
                            centered
                            ref="my-modal"
                            title="Penempatan"
                            ok-title="Simpan"
                            cancel-title="Bersihkan"
                            cancel-variant="outline-secondary"
                            @show="resetModal"
                            @hidden="resetModal"
                            @ok="handleOk"
                            size="lg"
                          >
                          <validation-observer
                            ref="warehouseForm"
                          >
                          <b-form 
                            @submit.stop.prevent="handleSubmit"
                          >
                            <b-row>
                              <b-col 
                                lg="12"
                                md="12"
                              >        
                                <div role="group">
                                  <label for="input-live">{{ $t('Form.Instansi.Name') }}</label>
                                      <validation-provider
                                          #default="{ errors }"
                                          name="Name"
                                          vid="name"
                                          rules="required"
                                      >
                                          <b-form-input
                                              v-model="name"
                                              id="mc-first-name"
                                          />
                                          <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                  </div>
                              </b-col>
                              <b-col
                                cols="12"
                              >
                                <div role="group">
                                    <label for="input-live">{{ $t('Form.Instansi.Description') }}</label>
                                    <b-form-textarea
                                        id="notes"
                                        v-model="description"
                                        rows="2"
                                        />
                                </div>
                              </b-col>
                              <b-col
                                cols="12"
                              >         
                                  <b-card-text class="mb-0">
                                      {{ $t('Form.Instansi.Status') }}
                                  </b-card-text>
                                  <b-form-checkbox
                                      v-model="status_checkbox"
                                      checked="true"
                                      class="custom-control-primary"
                                      name="check-button"
                                      switch
                                  />
                              </b-col>

                            </b-row>
                          </b-form>
                          </validation-observer>
                          </b-modal>
                        <!-- End Modal  -->                    
                    </b-col>

                    <b-row>
                      <b-col
                        md="12"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                      >
                            <b-table 
                                :fields="field" 
                                :items="itemsDetail"
                                responsive
                                sticky-header
                                head-variant="light"
                            >
                            <template #head()="scope">
                              <div
                                class="text-nowrap">
                                {{ $t(`Table.Warehouse.${scope.label}`) }}
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <b-badge 
                                  pill
                                  :variant="status[1][data.value]">
                                  {{ status[0][data.value] }}
                                </b-badge>
                            </template>
                            <template #cell(id)="data">
                                <div class="text-nowrap">
                                <feather-icon
                                    :id="`warehouse-row-${data.item.id}-send-icon`"
                                    icon="EditIcon"
                                    class="cursor-pointer"
                                    size="20"
                                />
                                <b-tooltip
                                    title="Edit Warehouse"
                                    class="cursor-pointer"
                                    :target="`warehouse-row-${data.item.id}-send-icon`"
                                />

                                <feather-icon
                                    @click="showMsgBoxRemoveWarehouse(data.item.id)"
                                    :id="`warehouse-row-${data.item.id}-preview-icon`"
                                    icon="TrashIcon"
                                    size="20"
                                    class="mx-2 cursor-pointer"
                                />
                                <b-tooltip
                                    title="Delete Warehouse"
                                    class="cursor-pointer"
                                    :target="`warehouse-row-${data.item.id}-preview-icon`"
                                />
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                  </b-row>
                </b-card>
            </template>

            <template #cell(status)="data">
                <b-badge 
                  pill
                  :variant="status[1][data.value]">
                  {{ status[0][data.value] }}
                </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(id)="data">
                <div class="text-nowrap">
                <feather-icon
                    :id="`instansi-row-${data.item.id}-send-icon`"
                    icon="EditIcon"
                    class="cursor-pointer"
                    size="20"
                />
                <b-tooltip
                    title="Edit Warehouse"
                    class="cursor-pointer"
                    :target="`instansi-row-${data.item.id}-send-icon`"
                />

                <feather-icon
                    @click="showMsgBoxRemove(data.item.id)"
                    :id="`instansi-row-${data.item.id}-preview-icon`"
                    icon="TrashIcon"
                    size="20"
                    class="mx-2 cursor-pointer"
                />
                <b-tooltip
                    title="Delete Warehouse"
                    class="cursor-pointer"
                    :target="`instansi-row-${data.item.id}-preview-icon`"
                />
                </div>
            </template>
        </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('Table.Showing') }} {{ $t('Table.Of') }} {{ $t('Table.Entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            :value="1"
            :total-rows="10"
            :per-page="pageLength"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { 
  BCard,
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput, 
  BFormCheckbox, 
  BForm, 
  BButton, 
  BTable,
  BFormRadioGroup, 
  BFormTextarea, BModal, VBModal, BCardText, BPagination, BBadge, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import store from '@/store/index'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: { 
    BCard,
    BRow, 
    BCol, 
    BFormGroup, 
    BFormInput, 
    BFormCheckbox, 
    BForm, 
    BButton, 
    BFormRadioGroup, 
    BFormTextarea, BModal, VBModal, BCardText,  BTable, BPagination, BBadge, BTooltip,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      fields: [
        'Check',
        { key: 'name', label: 'Name' },  
        { key: 'branding', label: 'Branding' },       
        { key: 'description', label: 'Description' },
        { key: 'status', label: 'Status' },
        { key: 'id', label: 'Actions' },
      ],
      items: [],
      field: [
        { key: 'name', label: 'Name' },        
        { key: 'description', label: 'Description' },
        { key: 'status', label: 'Status' },
        { key: 'id', label: 'Actions' },
      ],
      itemsDetail: [],
      id_instansi: '',
      status: [{
        false: 'Inactive', true: 'Active',
      },
      {
        false: 'light-danger', true: 'light-success',
      }],
      status_checkbox: '',
      name: '',
      description: '',
      required,
    }
  },
  methods: {
    fetchDataListWarehouse(id, status) { 
      if(!status){
        moduleApi.fetchDataWarehouse(id)
        .then(response => {
            this.itemsDetail = response.data.data
            this.id_instansi = id
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
      }
      this.itemsDetail = []
    },
    fetchDataListInstansi() { 
      moduleApi.fetchDataCustomers()
        .then(response => {
            this.items  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    resetModal() {
      this.name = ''
      this.description = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() { 
      this.$refs.warehouseForm.validate().then(success => {
        if (success) {
          moduleApi.createWarehouse({
            name: this.name,
            description: this.description,
            status: this.status_checkbox,
            id_instansi: this.id_instansi
          })
          .then(response => {
            this.fetchDataListWarehouse(this.id_instansi, false)
            this.$refs['my-modal'].toggle('#toggle-btn')           
          })
          .catch(error => {
            this.$refs.warehouseForm.setErrors(error.response.data.error)
          })
        }
      })
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.remove(id)
          }
        })
    },
    remove (id) {
      moduleApi.deleteInstansi({
        id: id,
        deleted_at: new Date().toISOString()
      })
        .then( response => {
          this.fetchDataListInstansi()
        })
        .catch( error => {
          console.log(error)
        })
    },
    showMsgBoxRemoveWarehouse(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.removeWarehouse(id)
          }
        })
    },
    removeWarehouse (id) {
      moduleApi.deleteWarehouse({
        id: id,
        deleted_at: new Date().toISOString()
      })
        .then( response => {
          this.fetchDataListWarehouse(this.id_instansi, false)
        })
        .catch( error => {
          console.log(error)
        })
    },
  },
  created () {
    this.fetchDataListInstansi()
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>